import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import Bio from "../img/bio.png";
import Model from "../img/model.png";
import Stylist from "../img/stylist.png";
import Artist from "../img/artist.png";
import { Link } from "gatsby";

// eslint-disable-next-line
export const AboutPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="section about">
      <Helmet title={`Itza | About`} />
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", height: "50vh", alignItems: "center"}}>
          <div style={{display: "flex",flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center"}}>
            <img
              style={{padding: "30px 30px 20px 30px", maxWidth: "100%", width: "500px", height: "auto"}}
              src={Bio}
              alt="Bio"
              className="bio"
            />
            <div style={{padding: "0 30px 60px 30px", display: "flex", alignItems: "center", maxWidth: "500px", width: "100%", justifyContent: "center"}}>
              <Link to="/modelling" title="Logo">
                <img
                style={{width: "100px",maxWidth: "80%", height: "auto", padding: "5px", marginRight: "10px"}}
                src={Model}
                alt="Model"
                className="model"
                />
              </Link>
              <Link to="/styling" title="Logo">
                <img
                style={{width: "100px", maxWidth: "80%", height: "auto" ,padding: "5px", marginRight: "10px"}}
                src={Stylist}
                alt="Stylist"
                className="stylist"
                />
              </Link>
              <Link to="/" title="Logo">
                <img
                style={{width: "100px", maxWidth: "80%", height: "auto", padding: "5px", marginRight: "10px"}}
                src={Artist}
                alt="Artist"
                className="artist"
                />
              </Link>
            </div>
          </div>
          <div className="more-info" style={{maxWidth: "100%", width: "500px", display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
            <div className="col" style={{display: "flex", flexDirection: "column", width: "33%", margin: "10px"}}>
              <h1>Clients</h1>
              <a href="http://instagram.com/f.miller.skincare" class="link" target="_blank">F. MILLER</a>  

              <a href="http://instagram.com/soopsoop" class="link" target="_blank">Soopsoop</a>

              <a href="http://instagram.com/assistive_touch" class="link" target="_blank">Assistive Touch</a>

              <a href="http://instagram.com/96tearsvintage" class="link" target="_blank">96 Tears Vintage</a>

              <a href="http://instagram.com/greyroomshop" class="link" target="_blank">Grey Room Shop</a>

              <a href="http://instagram.com/juniper.atelier_" class="link" target="_blank">Juniper Atelier</a>

              <a href="http://instagram.com/vintageoutlaw" class="link" target="_blank">Vintage Outlaw</a>
            </div>
            <div className="col" style={{display: "flex", flexDirection: "column", width: "33%", margin: "10px" }}>
              <h1>Collaborations</h1>
              <a href="http://instagram.com/katrina.cervoni" class="link" target="_blank">Katrina Cervoni</a> <a href="http://instagram.com/nillynillynillynilly" class="link" target="_blank">Anil Hanasoge (Nilly)</a>

              <a href="http://instagram.com/mediumwelll" class="link" target="_blank">Zhongqi Liu (MediumWelll</a>

              <a href="http://instagram.com/sloanie___g" class="link" target="_blank">Sloane Bartley</a>

              <a href="http://instagram.com/aranhahahaha" class="link" target="_blank">Justin Aranha Studio</a>

              <a href="http://instagram.com/dvdscc" class="link" target="_blank">DVDSCC</a>
            </div>
            <div className="col" style={{display: "flex", flexDirection: "column", width: "33%", margin: "10px"}}>
          <h1>Contact</h1>
            <a href="mailto:itzamacedarustecki@gmail.com" class="link">itzamacedarustecki@gmail.com</a>

            <a href="http://instagram.com/itza_m_r" target="_blank" class="link">Instagram</a>
          </div>
          </div>
          {/* <div style={{maxWidth: "100%", width: "500px", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}> */}
            {/* <h1>Contact</h1>
            <a href="mailto:itzamacedarustecki@gmail.com" class="link">itzamacedarustecki@gmail.com</a>

            <a href="http://instagram.com/itza_m_r" target="_blank" class="link">Instagram</a> */}
          {/* </div> */}
          {/* <PageContent style={{maxWidth: "100%", width: "500px", display: "flex", textAlign: "center"}} className="content" content={content} /> */}
        </div>
        
    </div>
  );
};

AboutPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
